<template>
  <el-dialog
    id="EditRestrictRuleDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="624px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    @keydown.enter.native="onCommit"
  >
    <el-form ref="EditRestrictRuleForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <!-- <el-form-item prop="name" label="Назва правила">
        <el-input v-model="model.name" ref="nameInput" autocomplete="off" :placeholder="$t('Не обрано')"></el-input>
      </el-form-item> -->
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:270px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="parentRules" :label="$t('Батьківські правила')">
          <el-tooltip :content="parentRulesComputedTooltip" placement="top" effect="light">
            <el-input v-model="parentRulesComp" autocomplete="off" suffix-icon="el-icon-more" @mousedown.native="findRule" readonly></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="type" :label="$t('Умова виконання')">
          <el-select style="width:100%" v-model="model.applyCondition">
            <el-option v-for="item in selectOptions.applyCondition" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="plan" :label="$t('План, значення') + planMesasureFromRestrictComp">
              <el-input v-model="model.plan" ref="planInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="bonus" :label="$t('Бонус, значення') + bonusMeasureFromRestrictComp">
              <el-input v-model="model.bonus" ref="bonusInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="notes" :label="$t('Примітка')">
          <el-input type="textarea" autosize v-model="model.notes" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <FindParentRules @find-commit="onFindCommit" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditRestrictRule",
  components: { FindParentRules: () => import("@/components/forms/FindParentRules") },
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
    var digitalValueValidateRule = {
      validator: (rule, value, callback) => {
        if (!value || (!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error("Поле має бути невід'ємним числом"));
        } else {
          callback();
        }
      },
    };
    var digitalValueValidateRuleWithAllowZero = {
      validator: (rule, value, callback) => {
        if (value !== 0 || (!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error("Поле має бути невід'ємним числом"));
        } else {
          callback();
        }
      },
    };
    return {
      loading: false,
      dialogShow: false,
      title: "Створення нового правила",
      operation: null,
      rules: {
        applyCondition: [defaultValidateRule],
        plan: [/*defaultValidateRule,*/ digitalValueValidateRule],
        bonus: [/*defaultValidateRule,*/ digitalValueValidateRuleWithAllowZero],
      },
      selectOptions: {
        applyCondition: [
          { value: "and", label: "Усі батьківські правила виконуються" },
          { value: "or", label: "Хоча б одне батьківське правило виконується" },
        ],
      },
      model: {},
      initModel: {},
    };
  },

  methods: {
    findRule() {
      bus.$emit("forms.rules.find.show", {
        title: $tt("Пошук батьківських правил"),
        singleRecord: false,
        contractId: this.$store.getters["contracts/contract"]()._id,
        parentRules: this.model.parentRules,
      });
    },
    onFindCommit(data) {
      this.parentRulesComp = data.result;
    },
    onCommit() {
      this.$refs.EditRestrictRuleForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditRestrictRuleForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    prepareForCommit(data) {
      data.plan = data.plan && !this.isNumeric(data.plan) ? data.plan.split(",").join(".") : data.plan;
      data.bonus = data.bonus && !this.isNumeric(data.bonus) ? data.bonus.split(",").join(".") : data.bonus;
      return data;
    },
  },
  created() {
    bus.$on("forms.rules.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({ plan: 0, bonus: 0 }, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.rules.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    parentRulesComp: {
      get() {
        if (!this.model.parentRules) return null;
        else {
          return $tt("Обрано {count} правил", { count: this.model.parentRules.length });
        }
      },
      set(value) {
        this.model.parentRules = value;
      },
    },
    parentRulesComputedTooltip() {
      if (!this.model.parentRules) return $tt("Немає");
      else {
        return $tt("Обрано {count} правил", { count: this.model.parentRules.length });
      }
    },
    planMesasureFromRestrictComp() {
      var planMeasures = this.$store.getters["main/selectionOptions"].restricts.planMeasure;
      var restrict = this.$store.getters["restricts/restrict"]();
      var measure = planMeasures.find((m) => m.value == restrict.planMeasure);
      if (measure) return " (" + $tt(measure.label) + ")";
      else return "";
    },
    bonusMeasureFromRestrictComp() {
      var bonusMeasures = this.$store.getters["main/selectionOptions"].restricts.bonusMeasure;
      var restrict = this.$store.getters["restricts/restrict"]();
      var measure = bonusMeasures.find((m) => m.value == restrict.bonusMeasure);
      if (measure) return " (" + $tt(measure.label) + ")";
      else return "";
    },
  },
};
</script>
<style lang="scss">
#EditRestrictRuleDialog {
  .el-dialog {
    max-height: 430px !important;
  }
}
</style>
